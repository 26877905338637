import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import get from 'lodash/get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import ContactHero from '../components/contact/ContactHero';
import ContactIntro from '../components/contact/ContactIntro';
import TeamMembers from '../components/contact/TeamMembers';
import ConnectBlock from '../components/contact/ConnectBlock';
import ContactForm from '../components/contact/ContactForm';
import theme from '../styles/theme';

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
`;

class ContactPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const team = get(this, 'props.data.allContentfulTeamMember.edges');
    const page = get(this, 'props.data.allContentfulContactPage.edges')[0].node;

    return (
      <LayoutStyled colorScheme="dark">
        <SEO title={page.title}
          description={documentToPlainTextString(page.intro.json)}
          fullTitle={true}
          path={path} />
        <WhiteBackground>
          <ContactHero title={page.title}
                       intro={documentToReactComponents(page.intro.json)}/>
          <ContactIntro address={page.address}
                        number={page.phoneNumber}
                        email={page.emailAddress}
                        image={page.thumbnail.fluid}/>
          <ContactForm />
          <TeamMembers team={team} />
          <ConnectBlock block1Title={page.contentBlock1Title}
                        block1Content={documentToReactComponents(page.contentBlock1Content.json)}
                        block2Title={page.contentBlock2Title}
                        block2Content={documentToReactComponents(page.contentBlock2Content.json)}
                        image={page.image.fluid} />
        </WhiteBackground>
      </LayoutStyled>
    )
  }
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactQuery {
    allContentfulContactPage(limit: 1) {
      edges {
        node {
          title
          intro {
            json
          }
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1000
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          address
          phoneNumber
          emailAddress
          contentBlock1Title
          contentBlock1Content {
            json
          }
          contentBlock2Title
          contentBlock2Content {
            json
          }
          image: image {
            fluid(
              maxWidth: 1920
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulTeamMember(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          slug
          name
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1000
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photo: photo {
            fluid(
              maxWidth: 1000
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            json
          }
          position
          phone
          email
          linkedIn
        }
      }
    }
  }
`;



