import React, { Component } from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';

const ColStyled = styled(Col)`
    display: ${props => props.button && 'flex'};
    box-sizing: border-box;
    flex-direction: ${props => props.button && 'row'};
    align-items: ${props => props.button && 'center'};
    justify-content: ${props => props.button && 'center'};
    padding-top: ${props => props.button && '50px'};
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-left: 10px;
        padding-right: 10px;
        justify-content: ${props => props.button && 'flex-end'};
    }
`;

const StyledButton = styled.button`
    background-color: ${theme.colors.black};
    ${fonts.MaisonNeueDemi};
    height: 53px;
    width: 160px;
    border: none;
    text-decoration: none;
    color: ${theme.colors.white};
    font-size: 18px;
    line-height: 21.6px;
    font-weight: normal;
    text-transform: capitalize;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    &:hover {
        transition-duration: 0.4s;
        color: ${theme.colors.white};
      }

`;

const StyledInput = styled.input`
    padding-top: 20px;
    box-sizing: border-box;
    background-color: ${theme.colors.white} !important;    
    height: 70px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${theme.colors.grey};
    color: ${theme.colors.black};
    font-size: 16px;
    ${fonts.MaisonNeueDemi};
    line-height: 26px;
    @media(min-width: ${theme.breakpoints.medium}) {
        ${fonts.MaisonNeueLight};
        font-size: 18px;
    } 
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${theme.colors.black};
        text-transform: capitalize;
        
        //padding-left: 15px;
        font-size: 16px;
        ${fonts.MaisonNeueDemi};
        @media(min-width: ${theme.breakpoints.medium}) {
            ${fonts.MaisonNeueLight};
            font-size: 18px;
            line-height: 26px;
        } 
      }
      ::-moz-placeholder { /* Firefox 19+ */
        ${fonts.MaisonNeueLight};
        color: ${theme.colors.black};
        font-size: 16px;
         ${fonts.MaisonNeueDemi};
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            font-size: 18px;
            line-height: 26px;
        } 
      }
      :-ms-input-placeholder { /* IE 10+ */
        ${fonts.MaisonNeueLight};
        color: ${theme.colors.black};
        font-size: 16px;
         ${fonts.MaisonNeueDemi};
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            font-size: 18px;
            line-height: 26px;
        } 
      }
      :-moz-placeholder { /* Firefox 18- */
        ${fonts.MaisonNeueLight};
        color: ${theme.colors.black};
        font-size: 16px;
         ${fonts.MaisonNeueDemi};
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            font-size: 18px;
            line-height: 26px;
        } 
      }
      :-webkit-autofill,
      :-webkit-autofill:hover, 
      :-webkit-autofill:focus, 
      :-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 35px ${theme.colors.white} inset !important;
      }
      :-webkit-autofill {
        -webkit-text-fill-color:  ${theme.colors.black} !important;
        ${fonts.MaisonNeueDemi};
        color: ${theme.colors.black};
        font-size: 16px;
        @media(min-width: ${theme.breakpoints.medium}) {
            font-size: 18px;
            ${fonts.MaisonNeueLight};
        } 
      }
    }
`;

const StyledTextArea = styled.textarea`
    box-sizing: border-box;
    background-color: ${theme.colors.white} !important;    
    height: 85px;
    padding-top: 50px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${theme.colors.grey};
    color: ${theme.colors.black};
    text-transform: capitalize;
    font-size: 16px;
    resize: none;
    ${fonts.MaisonNeueDemi};
    @media(min-width: ${theme.breakpoints.medium}) {
        ${fonts.MaisonNeueLight};
        font-size: 18px;
        line-height: 26px;
    } 
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${theme.colors.black};
        font-size: 16px;
        ${fonts.MaisonNeueDemi};
        line-height: 26px;
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            ${fonts.MaisonNeueLight};
            font-size: 18px;
        } 
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: ${theme.colors.black};
        font-size: 16px;
        ${fonts.MaisonNeueDemi};
        line-height: 26px;
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            ${fonts.MaisonNeueLight};
            font-size: 18px;
        } 
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: ${theme.colors.black};
        font-size: 16px;
        ${fonts.MaisonNeueDemi};
        line-height: 26px;
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            ${fonts.MaisonNeueLight};
            font-size: 18px;
        } 
    }
    :-moz-placeholder { /* Firefox 18- */
        color: ${theme.colors.black};
        font-size: 16px;
        ${fonts.MaisonNeueDemi};
        line-height: 26px;
        text-transform: capitalize;
        //padding-left: 15px;
        @media(min-width: ${theme.breakpoints.medium}) {
            ${fonts.MaisonNeueLight};
            font-size: 18px;
        } 
    }

`;

const initialState = {
    name: '',
    company: '',
    email: '',
    telephone: '',
    formSubmitted: false,
    success: false
};

class Form extends Component {
    state = initialState;

    handleChange = (event) => {
        let target = event.target;

        let stateObject = {};
        stateObject[target.name] = target.value;

        this.setState(stateObject);
    };

    handleSubmit = (event) => {
        event.preventDefault();

        let contactForm = document.getElementById('contact-form');
        let formData = new FormData(contactForm);

        fetch(contactForm.getAttribute('action'), {
            method: 'POST',
            body: formData
        }).then((response) => response)
            .then((response) => {
                if (response.status === 200) {
                    this.setState(initialState);

                    this.setState({
                        formSubmitted: true,
                        success: true
                    });
                } else {
                    this.setState({
                        formSubmitted: true,
                        success: false
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    render() {

        return (
            <Container>
                <form id="contact-form" method="POST" name="contact"
                    data-netlify="true" data-netlify-honeypot="bee"
                    onSubmit={this.handleSubmit}>
                    <Row>
                        <ColStyled md={6}>
                            <StyledInput
                                id="name"
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                className="cursor-dot-black"
                                required
                                placeholder="name" />
                        </ColStyled>
                        <ColStyled md={6}>
                            <StyledInput
                                id="company"
                                type="text"
                                name="company"
                                value={this.state.company}
                                onChange={this.handleChange}
                                className="cursor-dot-black"
                                placeholder="company" />
                        </ColStyled>
                        <ColStyled md={6}>
                            <StyledInput
                                id="email"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                className="cursor-dot-black"
                                required
                                placeholder="email address" />
                        </ColStyled>
                        <ColStyled md={6}>
                            <StyledInput
                                id="telephone"
                                type="tel"
                                name="telephone"
                                value={this.state.telephone}
                                onChange={this.handleChange}
                                className="cursor-dot-black"
                                required
                                placeholder="telephone" />
                            <div style={{ display: 'none' }}>
                                <input name="bee" />
                                <input type="hidden" name="form-name" value="contact" />
                            </div>
                        </ColStyled>
                        <ColStyled >
                            <StyledTextArea
                                id="message"
                                name="message"
                                value={this.state.message}
                                onChange={this.handleChange}
                                className="cursor-dot-black"
                                required
                                placeholder="message" />
                        </ColStyled>
                        <ColStyled button>
                            <StyledButton type="submit" aria-label="Submit" className="cursor-dot-white">
                                Submit
                            </StyledButton>
                        </ColStyled>
                    </Row>
                </form>
            </Container>
        );
    }
}

export default Form;
