import React, {Component} from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Overlay from '../../resources/images/home_page/team-overlay.png';
import fonts from '../../styles/fonts';
import {Row, Col, Container} from 'styled-bootstrap-grid';
import TeamMemberPopUp from './TeamMemberPopUp';
import theme from '../../styles/theme';

const PicturesContainer = styled(Container)`
  height: auto;
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: auto;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 100%;
    height: 100%;
    flex-direction: row;
  }
`;

const Picture = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: ${props => props.isCardHovered ? 'scale(1.15)' : 'none'};
  transition: .5s;
`;

const PictureContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 375px;
  position: relative;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 100%;
    height: 530px;
    max-height: unset;
  }
`;

const ImageOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 220px;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: ${props => props.isCardHovered ? '1' : '0'};
  transition: .5s;
`;

const Name = styled.div`
  ${fonts.MaisonNeueDemi};
  color: ${theme.colors.white};
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 75px;
  text-align: center;
  z-index: 2;
  font-size: 22px;
  line-height: 24px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 32px;
    line-height: 34px;
  }
`;

const Position = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 12.5px;
  line-height: 24px;
  color: ${theme.colors.white};
  z-index: 2;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding-bottom: 50px;
  cursor: default;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
  }
`;

const RowStyled = styled(Row)`
  width: 100%;
`;

const Details = styled.div`
  opacity: ${props => props.isCardHovered ? '1' : '0'};
  transition: .1s;
  z-index: 1;
`;

const CursorOverlay = styled.button`
  height: 100%;
  width: 100%;
  border: 0;
  box-shadow: none;
  background: transparent;
  position: absolute;
  outline: none;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 2;
`;

const PopupContainer = styled.div`

`

class TeamMembers extends Component {
  state = {
    popUpIsOpened: false,
    member: null,
    index: 0,
    isHovered: null,
  };

  handlePopUp = (value, member, index) => this.setState({popUpIsOpened: value, member: member, index: index})

  changeMember = (index, value) => {

    let indexNumber;
    let teamMemberCount = this.props.team.length;

    if (index === this.props.team.length - 1 && value === 1) {
      indexNumber = 0;
    } else if (index === 0 && value === -1) {
      indexNumber = teamMemberCount - 1;
    } else {
      indexNumber = index + value;
    }

    return this.setState({member: this.props.team[indexNumber], index: indexNumber})
  };

  render() {
    const {handlePopUp, changeMember} = this;
    const {popUpIsOpened, member, index, isHovered} = this.state;
    const {team} = this.props;

    return (
      <React.Fragment>
        <PicturesContainer>
          <RowStyled>
            {team && team.map((member, index) => {
              return (
                <Col md={6} lg={4} onClick={() => handlePopUp(true, member, index)} key={index} className="cursor-dot-white">
                  <PictureContainer onMouseEnter={() => this.setState({isHovered: index})}
                                    onMouseLeave={() => this.setState({isHovered: null})}>
                    <Picture fluid={member.node.thumbnail.fluid} isCardHovered={isHovered === index}/>
                    <CursorOverlay/>
                    <Details isCardHovered={isHovered === index}>
                      <Name>{member.node.name}</Name>
                      <Position>{member.node.position}</Position>
                    </Details>
                    <ImageOverlay src={Overlay} isCardHovered={isHovered === index}/>
                  </PictureContainer>
                </Col>
              )
            })}
          </RowStyled>
        </PicturesContainer>

          {popUpIsOpened && member &&
          <TeamMemberPopUp member={member} handlePopUp={handlePopUp} changeMember={changeMember} index={index} />}

      </React.Fragment>
    )
  }
}

export default TeamMembers;
