import React, { Component } from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import CloseIcon from '../../resources/images/home_page/plus.svg';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LinkedInConnect from '../../resources/images/home_page/connect-linkedin.svg';
import ArrowLeft from '../../resources/images/home_page/arrow-left-contact.svg';
import ArrowRightWhite from '../../resources/images/home_page/arrow-right-contact-white.svg';
import ArrowRight from '../../resources/images/home_page/arrow-right-contact.svg';
import theme from '../../styles/theme';
import breakpoints from "../../styles/breakpoints";

const PopUpContainer = styled.div`
    height: ${props => props.fullHeight === true ? ' 100vh' : '100%'};
    bottom: ${props => props.fullHeight === true && '0'};
    width: 100%;
    position: fixed;
    z-index: 9999999;
    top: 0;
    overflow-y: scroll;
    background: ${theme.colors.black};
    @media(min-width: ${theme.breakpoints.medium}) {
        display: flex;
        flex-direction: row-reverse;
    }

`;
const Left = styled.div`
    background: ${theme.colors.black};
    width: 100%;
    box-sizing: border-box;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 40px;
    min-height: 50%;
    height: auto;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 100%;
        width: 50%;
        overflow-y: ${props => props.fullHeight === true && 'scroll'};
        box-sizing: border-box;
        justify-content: center;
        padding: 100px 10%;
    }
`;

const Right = styled.div`
    background: ${theme.colors.greyConnectBlock};
    box-sizing: border-box;
    //overflow-y: ${props => props.fullHeight === true && 'scroll'};
    width: 100%;
    height: 50%;
    min-height: 50%;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 100%;
        width: 50%;
    }
`;

const TeamMemberPhoto = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const MemberPosition = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.white};
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 19.2px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 21.6px;
    }
`;

const MemberName = styled.div`
  ${fonts.CircularMedium};
  font-size: 46px;
  line-height: 55.2px;
  color: ${theme.colors.white};
  padding-bottom: 30px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 35px;
    line-height: 42px;
  }
`;

const MemberDescription = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.white};
  padding-bottom: 30px;
`;

const MemberPhoneNumber = styled.div`
    ${fonts.MaisonNeueDemi};
    font-size: 18px;
    line-height: 28px;
    color: ${theme.colors.white};
    padding-bottom: 15px;
`;

const MemberEmail = styled.a`
    ${fonts.MaisonNeueDemi};
    font-size: 18px;
    line-height: 28px;
    color: ${theme.colors.white};
    outline: none;
    border: none;
    border-bottom: 1px solid ${theme.colors.white};
    background: transparent;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid ${theme.colors.white};
    }  
    margin-bottom: 15px;
`;

const ConnectMember = styled.a`
    height: 28px;
    padding-right: 30px;
    ${fonts.MaisonNeueDemi};
    font-size: 18px;
    line-height: 28px;
    color: ${theme.colors.white};
    outline: none;
    border: none;
    background: transparent url(${LinkedInConnect}) no-repeat;
    background-size: 15px;
    background-position-x: right;
    background-position-y: center;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }
`;

const ClosePopUp = styled.button`
    height: 46px;
    width: 46px;
    position: absolute;
    background: transparent url(${CloseIcon}) no-repeat;
    background-size: 100%;
    //transform: rotate(45deg);
    right: 35px;
    top: 35px;
    border: none;
    outline: none;
     -webkit-transform: ${props => props.hover === true ? 'rotate(405deg)' : 'rotate(45deg)'};
     transform: ${props => props.hover === true ? 'rotate(405deg)' : 'rotate(45deg)'};
     transition: .3s;
    @media(min-width: ${theme.breakpoints.medium}) {
        right: 70px;
        top: 70px;
    }
`;

// const MenuTransparentOverlay = styled.div`
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   z-index: 111110;
//   background: rgba(0, 0, 0, 0.6);
// `;

const PreviousMember = styled.div`
    height: 72px;
    width: 72px;
    background: ${theme.colors.white} url(${ArrowLeft}) no-repeat;
    background-size: 20px 10px;
    background-position-x: center;
    background-position-y: center;
`;

const NextMember = styled.div`
    height: 72px;
    width: 72px;
    background: ${theme.colors.white} url(${ArrowRight}) no-repeat;
    background-size: 20px 10px;
    background-position-x: center;
    background-position-y: center;
    @media(min-width: ${breakpoints.medium}){
      background: ${theme.colors.black} url(${ArrowRightWhite}) no-repeat;
      background-size: 20px 10px;
      background-position-x: center;
      background-position-y: center;
    }
`;

const SlideButtons= styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 36px);
    left:calc(50% - 72px);
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
`;


class TeamMemberPopUp extends Component {
    state = {
        width: 0,
        height: 0,
        isHovered: false,
    };

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            let cursor = document.getElementById('custom-cursor');
            if (cursor) {
                cursor.classList.add("white")
            }
            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
        }
    };

    componentWillUnmount = () => {
        if (typeof window !== 'undefined') {
            let cursor = document.getElementById('custom-cursor');
            if (cursor) {
                cursor.classList.remove("white")
            }
            window.removeEventListener('resize', this.updateWindowDimensions);
        }
    };

    updateWindowDimensions = () => {
        if (typeof window !== 'undefined') {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    };

    hoverButton = () => this.setState({
        isHovered: !this.state.isHovered
    });


    render() {
        const { height, isHovered } = this.state;
        const { member, handlePopUp, changeMember, index } = this.props;
        const { hoverButton } = this;
        let cursor = document.getElementById('custom-cursor');
       
        return (
            <PopUpContainer fullHeight={height < 1000} >
                <Right fullHeight={height < 1000}>
                    <TeamMemberPhoto fluid={member.node.thumbnail.fluid} />
                    <ClosePopUp onClick={() => handlePopUp(false)} onMouseEnter={hoverButton} onMouseLeave={hoverButton} hover={isHovered === true}/>
                </Right>
                <Left fullHeight={height < 1000} >
                    <MemberPosition>{member.node.position}</MemberPosition>
                    <MemberName>{member.node.name}</MemberName>
                    <MemberDescription>{documentToReactComponents(member.node.description.json)}</MemberDescription>
                    <MemberPhoneNumber>{member.node.phone}</MemberPhoneNumber>
                    <MemberEmail href={`mailto:${member.node.email}`} onMouseEnter={() => cursor.classList.add("dot-white")} onMouseLeave={() => cursor.classList.remove("dot-white")}>Email {member.node.name.split(' ')[0]}</MemberEmail>
                    <ConnectMember href={`${member.node.linkedIn}`} onMouseEnter={() => cursor.classList.add("dot-white")} onMouseLeave={() => cursor.classList.remove("dot-white")}>Connect</ConnectMember>
                </Left>
                <SlideButtons>
                    <PreviousMember onClick={() => changeMember(index, -1)} onMouseEnter={() => cursor.classList.add("dot-black")} onMouseLeave={() => cursor.classList.remove("dot-black")}/>
                    <NextMember onClick={() => changeMember(index, 1)} onMouseEnter={() => cursor.classList.add("dot-white")} onMouseLeave={() => cursor.classList.remove("dot-white")}/>
                </SlideButtons>
            </PopUpContainer>
        )
    }
}

export default TeamMemberPopUp;


