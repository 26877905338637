import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Circle from '../../resources/images/profile_page/grey-circle.svg';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ContactHeroContainer = styled(Container)`
    width: 100%;
    padding: 175px 35px 140px;
    background: url(${Circle}) no-repeat;
    background-size: 330px;
    background-position-x: 70px;
    background-position-y: calc(100% + 35px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 255px;
        background-position-x: calc(100% - 140px);
        background-position-y: 100px;
        background-size: 580px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 140px;
        padding-right: 140px;
    }
`;

const Title = styled.div`
    ${fonts.CircularMedium};
    color: ${theme.colors.black};
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 0px;
    padding-bottom: 25px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 90px;
        line-height: 100px;
        letter-spacing: -5px;
    }
`;
const Intro = styled.div`
    ${fonts.MaisonNeueLight};
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.black};
    max-width: 604px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 28px;
    }
`;

const ContactHero = ({ title, intro }) => {
    return (
        <ContactHeroContainer>
            <Fade duration={750}>
                <Title>{title}</Title>
            </Fade>
            <Fade duration={750} delay={100}>
                <Intro>{intro}</Intro>
            </Fade>
        </ContactHeroContainer>
    )
}

export default ContactHero;
