import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import ExternalLink from "../core/ExternalLink";
import {Fade} from "react-reveal";

const ContactIntroContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.large}) {
    height: 710px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

const ContactIntroImg = styled.div`
  width: 100%;
  height: 210px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: auto;
    max-height: 420px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    width: 35%;
    height: calc(100% - 70px);
    object-fit: cover;
    margin-top: 70px;
    max-height: 642px;
  }
`;
const ContactIntroBackground = styled.div`
  background: ${theme.colors.black};
  color: ${theme.colors.white};
  ${fonts.CircularMedium};
  width: 100%;
  font-size: 24px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 40px;
  @media(min-width: ${theme.breakpoints.large}) {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 70px;
    height: calc(100% - 70px);
  }
  @media(min-width: ${theme.breakpoints.xl}) {
    padding-left: 10%;
    padding-right: 10%;
  }
`;

const ColStyled = styled(Col)`
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Address = styled.div`
  padding-top: 35px;
  padding-bottom: 17.5px;
  box-sizing: border-box;
  max-width: 300px;
  a {
    color: ${theme.colors.white};
    text-decoration: none;
    &:hover {
      color: ${theme.colors.grey};
    }
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-right: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Contact = styled.div`
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 35px;
  padding-top: 17.5px;
  a {
    text-decoration: none;
    color: ${theme.colors.white};
    &:hover {
      color: ${theme.colors.grey};
    }
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-left: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ContactIntro = ({ image, address, number, email }) => {

    return (
        <ContactIntroContainer>
            <ContactIntroImg>
                <Fade duration={750} delay={100}>
                    <Img fluid={image}/>
                </Fade>
            </ContactIntroImg>
            <ContactIntroBackground className="cursor-white">
                <Container>
                    <Row>
                        <ColStyled md={6} lg={5}>
                            <Address>
                                <ExternalLink href="https://goo.gl/maps/HVm8Xda723onrhD87">
                                    <span className="cursor-dot-white">{address}</span>
                                </ExternalLink>
                            </Address>
                        </ColStyled>
                        <ColStyled md={6} lg={7}>
                            <Contact className="cursor-dot-white">
                                <ExternalLink href={'tel:'+ {number}}>{number}</ExternalLink>
                                <br />
                                <ExternalLink href={'mailto:'+{email}}>{email}</ExternalLink>
                            </Contact>
                        </ColStyled>
                    </Row>
                </Container>
            </ContactIntroBackground>
        </ContactIntroContainer>
    )
};

export default ContactIntro;



