import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ConnectContainer = styled(Container)`
  width: 100%;
`;

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
`;

const ColStyled = styled(Col)`
  padding: 80px 40px 70px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  background: ${props => props.black && `${theme.colors.black}`};
  background: ${props => props.grey && `${theme.colors.greyConnectBlock}`};
  @media(min-width: ${theme.breakpoints.large}) {
    height: auto;
    padding: 150px 10% 130px 10%;
  }
`;

const Title = styled.div`
  ${fonts.CircularMedium};
  font-size: 35px;
  line-height: 42px;
  color: ${theme.colors.white};
  width: 100%;
  padding-bottom: 35px;
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const Description = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.white};
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 28px;
  }
  a {
    color: ${theme.colors.white} !important;
    font-size: 16px;
    line-height: 20px;
    ${fonts.MaisonNeueLight};
    @media(min-width: ${theme.breakpoints.medium}) {
      font-size: 18px;
      line-height: 28px;
      ${fonts.MaisonNeueDemi};
    }
  }
  p {
    margin-bottom: 20px;
  }
`;

const FullWidthImageContainer = styled.div`
  display: none;
  @media(min-width: ${theme.breakpoints.medium}) {
    display: block;
    height: auto;
    overflow: hidden;
    max-height: 871px;
    width: 100%;
  }
`;

const ConnectImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ConnectBlock = (props) => {
    const { block1Title, block1Content, block2Title, block2Content, image } = props;
    return (
        <ConnectContainer className="cursor-white">
            <RowStyled>
                <ColStyled md={6} black>
                    <Title>{block1Title}</Title>
                    <Description>{block1Content}</Description>
                </ColStyled>
                <ColStyled md={6} grey>
                    <Title>{block2Title}</Title>
                    <Description>{block2Content}</Description>
                </ColStyled>
            </RowStyled>
            <FullWidthImageContainer>
                <Fade duration={750}>
                    <ConnectImage fluid={image}/>
                </Fade>
            </FullWidthImageContainer>
        </ConnectContainer>
    )
};

export default ConnectBlock;
