import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import Form from './Form';
import theme from '../../styles/theme';

const ContactFormContainer = styled(Container)`
    box-sizing: border-box;
    padding: 25px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding: 105px 105px 0 105px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 0;
    }
`;

const ContactForm = () => {
    return (
        <ContactFormContainer>
            <Row>
                <Col lg={7} lgOffset={5}>
                    <Form/>
                </Col>
            </Row>
        </ContactFormContainer>
    )
}

export default ContactForm;